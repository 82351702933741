<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ClientManageProfessionals',
  components: {
    Multiselect,
  },
  data: () => ({
    selection_type_options: [
      { text: 'Automatic: Follow Professional Preferences from this page', value: 'automatic' },
      { text: 'Manual: Follow Institution<>Professional assignment', value: 'manual' },
    ],
    tags_options: [
      { text: 'Regular PTs', value: 'is_regular' },
      { text: 'Demo PTs', value: 'is_demo' },
      { text: 'Contractor', value: 'is_contractor' },
      { text: 'Test PTs', value: 'is_test' },
    ],
    pt_extra_filter_tag_options: [
      { text: 'No whitelist/blacklist', value: 'none' },
      { text: 'Whitelist (After applying Professional availability requirements filter ADD specific PTs)', value: 'whitelist' },
      { text: 'Blacklist (After applying Professional availability requirements filter REMOVE specific PTs)', value: 'blacklist' },
    ],
    configuration: {
      loading: false,
      loaded: false,
      saving: false,
      data: {
        selection_type: 'manual',
        tags: [],
        extra_filter_type: 'none',
        filtered_professionals_ids: [],
        filtered_professionals: [],
      },
    },
    client: {
      loading: true,
      loaded: false,
      id: null,
      data: {},
    },
    professionals: {
      loading: true,
      loaded: false,
      items: [],
    },
    dry_run: {
      dpt: {
        treatment: { loading: false, error: false, total: 0 },
        intake: { loading: false, error: false, total: 0 },
      },
      bloom: {
        treatment: { loading: false, error: false, total: 0 },
        intake: { loading: false, error: false, total: 0 },
      },
      move: {
        treatment: { loading: false, error: false, total: 0 },
      },
    },
  }),
  beforeMount() {
    this.client.id = this.$route.params.clientID;
    this.fetchClient();
    this.fetchConfiguration();
  },
  computed: {
    overlay() {
      if (this.client.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching client',
        };
      }
      if (this.configuration.loading) {
        return {
          show: true,
          icon: 'arrow-clockwise',
          animation: 'spin',
          text: 'Fetching configuration',
        };
      }
      if (this.configuration.saving) {
        return {
          show: true,
          icon: 'save',
          animation: 'throb',
          text: 'Saving configuration',
        };
      }

      return {
        show: false,
        icon: '',
        animation: '',
        text: '',
      };
    },
  },
  methods: {
    fetchClient() {
      this.client.loading = true;
      return axios.get(`v1/clients/${this.client.id}`, { params: { by: 'id' } })
        .then(response => {
          this.client.data = response.data.client;
          this.client.loaded = true;
        })
        .catch(e => {
          this.$noty.error('Error loading client');
          console.error(e);
        })
        .finally(() => {
          this.client.loading = false;
        });
    },
    fetchProfessionals(search) {
      this.professionals.loading = true;
      return axios.get(
        'v1/users',
        {
          params: {
            limit: 10, offset: 0, role: 'Professional', client: 'back-office', search,
          },
        },
      )
        .then(response => {
          this.professionals.items = response.data.users.map(user => ({ id: user.id, name: user.username }));
          this.professionals.loading = false;
        })
        .catch(e => {
          this.$noty.error('Error loading professionals');
          console.error(e);
        })
        .finally(() => {
          this.professionals.loaded = true;
        });
    },
    dryRunConfigurations(unit, flow) {
      this.dry_run[unit][flow].loading = true;
      const usableUnit = unit === 'dpt' ? 'sword' : unit;
      axios.get(`/v1/clients/${this.client.data.id}/professionals`, {
        params: {
          institution_id: this.client.data.institution_id,
          ecosystem_unit: usableUnit,
          intake_flow: flow === 'intake' ? 1 : 0,
          treatment_flow: flow === 'treatment' ? 1 : 0,
          provides_remote_therapy: 1,
          selection_type: this.configuration.data.selection_type,
          tags: this.configuration.data.tags?.join(',') || null,
          extra_filter_type: this.configuration.data.extra_filter_type,
          filtered_professionals_ids: this.configuration.data.filtered_professionals_ids?.join(',') || null,
          enable_alert: false,
        },
      })
        .then(response => {
          this.dry_run[unit][flow].total = response.data.log_data.results_count;
        })
        .catch(e => {
          this.dry_run[unit][flow].error = true;
          console.error(e);
        })
        .finally(() => {
          this.dry_run[unit][flow].loading = false;
        });
    },
    openConfirmationModal() {
      this.updateConfigurationConditions();
      if (!this.runConditionsValidations()) {
        return;
      }
      this.$bvModal.show('modal-confirm-configuration');
      this.dryRunConfigurations('dpt', 'treatment');
      this.dryRunConfigurations('dpt', 'intake');
      this.dryRunConfigurations('bloom', 'treatment');
      this.dryRunConfigurations('bloom', 'intake');
      this.dryRunConfigurations('move', 'treatment');
    },
    fetchConfiguration() {
      this.configuration.loading = true;
      return axios.get(`v1/clients/${this.client.id}/professionals-configuration`)
        .then(response => {
          this.configuration.data = response.data.payload;
          this.configuration.data.tags = this.configuration.data.tags || [];
          this.configuration.loaded = true;
        })
        .catch(e => {
          if (e.response.status !== 404) {
            this.$noty.error('Error loading client configuration');
            console.error(e);
          }
        })
        .finally(() => {
          this.configuration.loading = false;
        });
    },
    saveConfiguration() {
      if (this.dry_run.dpt.treatment.total
        + this.dry_run.dpt.intake.total
        + this.dry_run.bloom.treatment.total
        + this.dry_run.bloom.intake.total
        + this.dry_run.move.treatment.total < 1) {
        this.$noty.error('cannot save because there are no PT\'s available');
        return;
      }
      this.$bvModal.hide('modal-confirm-configuration');
      const dataToSave = this.configuration.data;
      if (this.configuration.data.flags?.length <= 0) {
        dataToSave.flags = null;
      }
      this.configuration.saving = true;
      axios.post(`v1/clients/${this.client.id}/professionals-configuration`, dataToSave)
        .then(() => {
          this.$noty.success('Configuration saved');
        })
        .catch(e => {
          this.$noty.error('Error saving configuration');
          console.error(e);
        })
        .finally(() => {
          this.configuration.saving = false;
        });
    },
    runConditionsValidations() {
      if (this.configuration.data.extra_filter_type !== 'none') {
        if (!this.configuration.data.filtered_professionals_ids || this.configuration.data.filtered_professionals_ids.length < 1) {
          this.$noty.error(`You must select at least one professional for the ${this.configuration.data.extra_filter_type} filter`);
          return false;
        }
      }
      if (this.configuration.data.selection_type === 'automatic') {
        if ((!this.configuration.data.tags || this.configuration.data.tags.length < 1) && this.configuration.data.extra_filter_type === 'none') {
          this.$noty.error('You must select at least one tag or whitelist/blacklist professionals');
          return false;
        }
      }
      return true;
    },
    updateConfigurationConditions() {
      if (this.configuration.data.selection_type === 'manual') {
        this.configuration.data.tags = [];
        this.configuration.data.extra_filter_type = 'none';
      }

      if (this.configuration.data.extra_filter_type === 'none') {
        this.configuration.data.filtered_professionals = null;
      }

      if (this.configuration.data.filtered_professionals?.length > 0) {
        this.configuration.data.filtered_professionals_ids = this.configuration.data.filtered_professionals.map(prof => prof.id);
      } else {
        this.configuration.data.filtered_professionals_ids = null;
      }
    },
    resetModal() {
      this.dry_run.dpt.treatment.total = 0;
      this.dry_run.dpt.intake.total = 0;
      this.dry_run.bloom.treatment.total = 0;
      this.dry_run.bloom.intake.total = 0;
      this.dry_run.move.treatment.total = 0;
    },
  },
};
</script>

<template>
  <b-container class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h3>Managing professionals for {{ client.data.display_name }}</h3>
      </b-col>
    </b-row>
    <b-overlay :show="overlay.show">
      <template #overlay>
        <div class="text-center">
          <b-icon :animation="overlay.animation" :icon="overlay.icon" font-scale="3"></b-icon>
          <p id="cancel-label">{{ overlay.text }}</p>
        </div>
      </template>
      <b-card>
        <b-card-text>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Professional availability flow" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="configuration.data.selection_type" :options="selection_type_options"
                  :aria-describedby="ariaDescribedby" name="selection_type" stacked required/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="configuration.data.selection_type === 'automatic'">
            <b-col cols="12">
              <b-form-group label="Professionals availability requirements" v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group v-model="configuration.data.tags" :options="tags_options"
                  :aria-describedby="ariaDescribedby" name="tags" stacked required/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Whitelist/Blacklist Professionals" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="configuration.data.extra_filter_type" :options="pt_extra_filter_tag_options"
                  :aria-describedby="ariaDescribedby" name="pt_extra_filter_tag_options" stacked required/>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="configuration.data.extra_filter_type !== 'none'">
              <b-form-group label="Professionals list" v-slot="{ ariaDescribedby }">
                <multiselect v-model="configuration.data.filtered_professionals" :options="professionals.items"
                             :multiple="true" @search-change="fetchProfessionals"
                             :loading="professionals.loading" :searchable="true"
                             :clear-on-select="false" :close-on-select="false"
                             placeholder="search professionals ..." label="name" track-by="id"/>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col cols="12">
              <b-button variant="primary" @click="openConfirmationModal" class="float-right">Save</b-button>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-overlay>
    <b-modal id="modal-confirm-configuration" @hide="resetModal">
      <template #modal-title>
        <b-icon icon="exclamation-triangle-fill" font-scale="1.5" class="mr-2"></b-icon>
        <span>Confirm configuration</span>
      </template>

      <b-row>
        <b-col cols="12">
          <p>Please check if these numbers make sense: </p>
          <ul>
            <li>Number of dpt PTs:
              <ul>
                <li>
                  Treatment PTs: <b-spinner v-if="dry_run.dpt.treatment.loading" type="border" small></b-spinner>
                  <template v-else>{{ dry_run.dpt.treatment.total }}</template>
                  <b-badge variant="danger" v-if="dry_run.dpt.treatment.error">error loading</b-badge>
                </li>
                <li>
                  Intake PTs: <b-spinner v-if="dry_run.dpt.intake.loading" type="border" small></b-spinner>
                  <template v-else>{{ dry_run.dpt.intake.total }}</template>
                  <b-badge variant="danger" v-if="dry_run.dpt.intake.error">error loading</b-badge>
                </li>
              </ul>
            </li>
            <li>Number of Bloom PTs:
              <ul>
                <li>
                  Treatment PTs: <b-spinner v-if="dry_run.bloom.treatment.loading" type="border" small></b-spinner>
                  <template v-else>{{ dry_run.bloom.treatment.total }}</template>
                  <b-badge variant="danger" v-if="dry_run.bloom.treatment.error">error loading</b-badge>
                </li>
                <li>
                  Intake PTs: <b-spinner v-if="dry_run.bloom.intake.loading" type="border" small></b-spinner>
                  <template v-else>{{ dry_run.bloom.intake.total }}</template>
                  <b-badge variant="danger" v-if="dry_run.bloom.intake.error">error loading</b-badge>
                </li>
              </ul>
            </li>
            <li>Number of move PTs:
              <ul>
                <li>
                  Treatment PTs: <b-spinner v-if="dry_run.move.treatment.loading" type="border" small></b-spinner>
                  <template v-else>{{ dry_run.move.treatment.total }}</template>
                  <b-badge variant="danger" v-if="dry_run.move.treatment.error">error loading</b-badge>
                </li>
              </ul>
            </li>
          </ul>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button variant="primary" @click="saveConfiguration">Yes, apply configuration</b-button>
        <b-button variant="secondary" @click="cancel">Cancel</b-button>
      </template>
    </b-modal>
  </b-container>
</template>
